import axios from "axios";
import history from "../history";

//Configuration
const fetch = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL + "/api/",
  timeout: 60000,
});

fetch.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem("token");
    if (jwtToken) {
      config.headers["Authorization"] = "Bearer " + jwtToken;
    }
    if (!jwtToken && !config.headers["public-request"]) {
      history.push("/");
      window.location.reload();
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

fetch.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.log(error);

    if (error.response?.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      history.push("/");
      window.location.reload();
    }

    return Promise.reject(error);
  }
);

const ApiService = {};

//Donation services

ApiService.fetchDonationServices = () => {
  return fetch({
    url: "/donation-type",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchDonationData = (page) => {
  return fetch({
    url: `/donation-track?page=${page}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};



ApiService.fetchDonationService = (slug) => {
  return fetch({
    url: `/donation-type/${slug}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchYatraEnquiry = () => {
  return fetch({
    url: `/yatra/list`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchYatrasJDataForAvai = () => {
  return fetch({
    url: `/yatra/data-list`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};


ApiService.fetchYatraEnq = (slug) => {
  return fetch({
    url: `/yatra/enq/${slug}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

//Bank Details

ApiService.fetchBankDetailsData = () => {
  return fetch({
    url: "/bank/list",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

//Shedule

ApiService.fetchSheduleData = () => {
  return fetch({
    url: "/sheduled/list",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

//----------------------------------------------

ApiService.getIcsData = (slug) => {
  return fetch({
    url: `/getIcsData/${slug}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getServiceData = (slug) => {
  return fetch({
    url: `/getServiceData/${slug}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getIcsDonation = (slug) => {
  return fetch({
    url: `/getIcsDonation/${slug}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getIcsScheme = (slug) => {
  return fetch({
    url: `/getIcsScheme/${slug}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getIcsDonation_b = (slug) => {
  return fetch({
    url: `/getIcsDonation_b/${slug}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchNameFromIcs = (slug) => {
  return fetch({
    url: `/fetchNameFromIcs/${slug}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.sendDataToIcs = (details) => {
  return fetch({
    url: `/sendDataToIcs`,
    data: details,
    method: "POST",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.icsUpdate = (updateData) => {
  return fetch({
    url: `/icsUpdate`,
    data: updateData,
    method: "POST",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.createServicetoIcs = (Data) => {
  return fetch({
    url: `/createServicetoIcs`,
    data: Data,
    method: "POST",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.createIndividualQuick = (Data) => {
  return fetch({
    url: `/createIndividualQuick`,
    data: Data,
    method: "POST",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.handelservRequest = (Data) => {
  return fetch({
    url: `/handelservRequest`,
    data: Data,
    method: "POST",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.joinFamilyrelationshipIcs = (Data) => {
  return fetch({
    url: `/joinFamilyrelationshipIcs`,
    data: Data,
    method: "POST",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchJoinfamilyIcs = (Data) => {
  return fetch({
    url: `/fetchJoinfamilyIcs`,
    data: Data,
    method: "POST",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.icsPanUpdateToIcs = (Data) => {
  return fetch({
    url: `/icsPanUpdateToIcs`,
    data: Data,
    method: "POST",
    headers: {
      "public-request": "true",
    },
  });
};

//------------------------------------------------

ApiService.fetchYatraEnqUpdate = (slug) => {
  return fetch({
    url: `/yatra/enq/Update?data=${slug}`,
    data: slug,
    method: "PUT",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchPopularDonations = () => {
  return fetch({
    url: `/donation-type/popular`,
    headers: {
      "public-request": "true",
    },
  });
};

//Donation

ApiService.donate = (data) => {
  return fetch({
    url: "/donate",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchDonationByTransactionId = (ref_id) => {
  return fetch({
    url: `/fetch-donation-by-transaction-id/${ref_id}`,
    method: 'GET'
  })
}

ApiService.report = (data) => {
  return fetch({
    url: "/report",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

// ApiService.icsdonate = (data) => {
//   return fetch({
//     url: "https://srv.konsoftech.in/ics/api/ah?oper=DONDATA&apikey=CFRcu3sMY0LrMkjGAUYrSIPJBSLvwniT",
//     method: "POST",
//     data,
//     headers: {
//       "public-request": "true",
//     },
//   });
// };

ApiService.donateCampaign = (data) => {
  return fetch({
    url: "/donate-campaign",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.download80g = (file) => {
  //  console.log(file)
  return fetch({
    url: `/download-80g/${file}`,
    method: "GET",
    responseType: "blob",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.downloadNon80g = (details) => {
  return fetch({
    url: `/download-Non80g?data=${details}`,
    data: details,
    method: "PUT",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.downloadImg = (file) => {
  //  console.log(file)
  return fetch({
    url: `/download-downloadImg/${file}`,
    method: "GET",
    responseType: "blob",
    headers: {
      "public-request": "true",
    },
  });
};

// DMS
ApiService.downloadAnnadaanCertificate = (ref_id) => {
  //  console.log(file)
  return fetch({
    url: `/download-annadaan-certificate/${ref_id}`,
    method: "GET",
    responseType: "blob",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.check80g = (id, donationType) => {
  return fetch({
    url: `/check-80g/${id}/${donationType}`,
    headers: {
      "public-request": "true",
    },
  });
};

//Festivals

ApiService.fetchFestivals = () => {
  return fetch({
    url: "/festival/list",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

//Yatras

ApiService.fetchYatras = () => {
  return fetch({
    url: "/yatra/list",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

//Eventss

ApiService.fetchEvents = () => {
  return fetch({
    url: "/event/list",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

//Eventss

ApiService.fetchDarshan = () => {
  return fetch({
    url: "/darshan/list",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.downloadCalendar = (file) => {
  return fetch({
    url: `/festival/${file}/download-calendar`,
    method: "GET",
    responseType: "blob",
    headers: {
      "public-request": "true",
    },
  });
};
//Why krishna

ApiService.fetchHomepageVideo = () => {
  return fetch({
    url: "/why-krishna/home",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchVideos = () => {
  return fetch({
    url: "/why-krishna",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchClassVideos = () => {
  return fetch({
    url: "/class-video",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchGalleryImg = () => {
  return fetch({
    url: "/gallery-img",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

//Courses

ApiService.fetchCourses = () => {
  return fetch({
    url: "/courses",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

//Services

ApiService.fetchServices = () => {
  return fetch({
    url: "/services",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

//Enquire
ApiService.createEnquiry = (data) => {
  return fetch({
    url: "/enquiry/create",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.createYatraEnquiry = (data) => {
  console.log("datatat", data);

  return fetch({
    url: "/yatra/enquiry/create",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.newConnect = (data) => {
  return fetch({
    url: "/connect/new",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.newVolunteer = (data) => {
  return fetch({
    url: "/volunteer/new",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchWallpapers = () => {
  return fetch({
    url: "/wallpapers",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchPaymentDetails = (id, donationType) => {
  return fetch({
    url: `/fetch-payment-details/${id}/${donationType}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchYatraDetails = (id) => {
  return fetch({
    url: `/fetch-yatra-details/${id}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

// Fetch Meta Data
ApiService.fetchMetaData = (slug) => {
  return fetch({
    url: `/fetch-meta-data/${slug}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.joinFamily = (data) => {
  return fetch({
    url: `/join-family/new`,
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchCampaign = (slug) => {
  return fetch({
    url: `/fetch-campaign/${slug}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.checkTrackCampaign = (slug) => {
  return fetch({
    url: `/track-campaign/${slug}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchCampaigns = () => {
  return fetch({
    url: `/fetch-campaigns`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.lifeMembership = (data) => {
  return fetch({
    url: "/life-membership",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.retryDonation = (id) => {
  return fetch({
    url: `/retry-payment/${id}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.retryLifeMembership = (id) => {
  return fetch({
    url: `/retry-life-membership/${id}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchDonationDetails = (data) => {
  return fetch({
    url: `/fetch-donation-details/${data.id}?type=${data.type}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchDonationDetailss = (id) => {
  return fetch({
    url: `/fetch-donation-details/${id}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.updatePanDetails = (details) => {
  return fetch({
    url: `/update-pan-details?type=${details.type}`,
    data: details.data,
    method: "PUT",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchFailedCampaignDetails = (id) => {
  return fetch({
    url: `/fetch-failed-campaign-details?id=${id}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.sendOtpp = (data) => {
  return fetch({
    url: `/send-otpp?number=${data.number}`,
    method: "POST",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.sendOtp = (data) => {
  return fetch({
    url: `/send-otp?number=${data.number}`,
    method: "POST",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.sendOtpYatra = (data) => {
  return fetch({
    url: `/send-otpyatra?number=${data.number}&titleYatra=${data.titleYatra}`,
    method: "POST",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.loginUser = (data) => {
  return fetch({
    url: `/login`,
    data,
    method: "POST",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.loginUserYatra = (data) => {
  return fetch({
    url: `/loginyatra`,
    data,
    method: "POST",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.loginUserr = (data) => {
  return fetch({
    url: `/loginn`,
    data,
    method: "POST",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchUser = () => {
  return fetch({
    url: "/user",
    method: "GET",
  });
};

ApiService.updateProfile = (data) => {
  return fetch({
    url: "/user-update",
    method: "PUT",
    data,
  });
};

ApiService.fetchUserDonation = (page) => {
  return fetch({
    url: `/user-donations?page=${page}`,
    method: "GET",
  });
};

ApiService.fetchUserDonationNitya = (page) => {
  return fetch({
    url: `/user-donationsnitya?page=${page}`,
    method: "GET",
  });
};

ApiService.fetchJoinFamily = (phone) => {
  return fetch({
    url: `/fetch-join-family?phone=${phone}`,
    method: "GET",
  });
};

ApiService.fetchLiveVideos = () => {
  return fetch({
    url: "/fetch-live-video",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.hasWatchLive = () => {
  return fetch({
    url: "/has-watch-live",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.cancelDonation = (orderId) => {
  return fetch({
    url: `/cancel-donation/${orderId}`,
    method: "DELETE",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.cancelLifeMembership = (orderId) => {
  return fetch({
    url: `/cancel-life-membership/${orderId}`,
    method: "DELETE",
    headers: {
      "public-request": "true",
    },
  });
};


ApiService.downloadReciept = (data) => {
  return fetch({
    url: `/downloadReciept`,
    data: data,
    method: "POST",
    headers: {
      "public-request": "true",
    },
  });
};



ApiService.cancelCampaign = (orderId) => {
  return fetch({
    url: `/cancel-campaign/${orderId}`,
    method: "DELETE",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchLifeMembership = () => {
  return fetch({
    url: "/fetch-life-membership",
    method: "GET",
  });
};

ApiService.purchaseShopProduct = (data) => {
  return fetch({
    url: "/purchase-shop-product",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.purchaseShopProductram = (data) => {
  return fetch({
    url: "/purchase-shop-product-ram",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.purchasegauraPurimaProduct = (data) => {
  return fetch({
    url: "/purchase-gauraPurima-product",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchPurchaseDetails = (id) => {
  return fetch({
    url: `/fetch-purchase-details/${id}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.cancelPurchase = (id) => {
  return fetch({
    url: `/cancel-purchase/${id}`,
    method: "DELETE",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchUserPurchases = () => {
  return fetch({
    url: `/user-purchase`,
    method: "GET",
  });
};

ApiService.fetchBanner = (location) => {
  return fetch({
    url: `/banners?location=${location}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchKartikDeepdaans = () => {
  return fetch({
    url: `/fetch-kartik-deepdaans`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.createKartikDeepdaanPayment = (data) => {
  return fetch({
    url: `/create-kartik-deepdaans-payment`,
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.brithdaySlotBook = (data) => {
  return fetch({
    url: "/brithday/new",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};
ApiService.fetchBirthdaySlots = () => {
  return fetch({
    url: `/fetch-brithday_slot`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.updateBirthdaySlot = (_id, icsid, data, feedbacklink, editData) => {
  const phone = editData.phone;
  const donorname = editData.donorname;
  const whatsappfeedback = editData.whatsappfeedback;
  return fetch({
    url: `/update_brithday_slot`,
    data: {
      _id,
      icsid,
      ...data,
      feedbacklink,
      phone,
      donorname,
      whatsappfeedback,
    },
    method: "PUT",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.updateFeedback = (_id, data) => {
  return fetch({
    url: `/update_donorfeedback`,
    data: { _id, ...data },
    method: "PUT",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getStarValue = (icsid) => {
  return fetch({
    url: `/fetch-starvalue/${icsid}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchBookedSlots = (formattedDate, selectedTime) => {
  return fetch({
    url: `/fetch-booked_slot/${formattedDate}/${selectedTime}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchAllBookedDateSlots = (formattedDate) => {
  return fetch({
    url: `/fetch-All_booked_slot/${formattedDate}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.sendZoomLinkForBirthdayPuja = (data) => {
  return fetch({
    url: `/send_zoom_link/new`,
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};
ApiService.sendReminderForBirthdayPuja = (data) => {
  return fetch({
    url: `/send_reminder_massage/new`,
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.birthdayLogin = (data) => {
  // console.log("dadadatata", data);
  return fetch({
    url: "/birthday_login/new",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};



//LANTERN SEND OTP
ApiService.lanternLogin = (data) => {
  return fetch({
    url: "/lantern_login/new",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

//LANTERN VERIFY LOGIN
ApiService.verifyLogin = (data) => {
  return fetch({
    url: "lantern_login/verify",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.downloadExcelPuja = (data) => {
  return fetch({
    url: `/download-ExcelPuja?data=${data}`,
    data: data,
    method: "PUT",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.subscribeNityaSeva = (data) => {
  return fetch({
    url: "/subscribe_nityaSeva",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.nityaSevaCancel = (data) => {
  return fetch({
    url: `/nityaSevaCancel`,
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.nityaSevaPause = (data) => {
  return fetch({
    url: `/nityaSevaPause`,
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.saveGitaClass = (data) => {
  return fetch({
    url: `/save-gita-class`,
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.subscribeNityaSevaAutopay = (data) => {
  return fetch({
    url: "/subscribe_nityaSeva_autopay",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

// =====================================
//            DMS SERVICES
// =====================================

// Find or create a donor
ApiService.fetchDonor = (data) => {
  return fetch({
    url: "/dms/donor",
    method: "POST",
    data,
  });
};

ApiService.fetchDonorProfile = (donor_id) => {
  return fetch({
    url: `/dms/profile/${donor_id}`,
    method: "GET",
  });
};

ApiService.fetchDonorById = (donor_id) => {
  return fetch({
    url: `/dms/donor/${donor_id}`,
    method: "GET",
  });
};

ApiService.updateDefaultProfile = ({ profileId, donorId }) => {
  return fetch({
    url: `/dms/profile/set-default/${profileId}?donor_id=${donorId}`,
    method: "GET",
  });
};

ApiService.fetchAllDonations = (donorId, page) => {
  return fetch({
    url: `/dms/donation/${donorId}?page=${page}`,
    method: "GET",
  });
};

ApiService.updateDonor = ({ id, data }) => {
  return fetch({
    url: `/dms/donor/${id}`,
    method: "PUT",
    data,
  });
};

ApiService.fetchRelatives = (donorId) => {
  return fetch({
    url: `/dms/donor/relatives/${donorId}`,
    method: "GET",
  });
}

ApiService.updateRelatives = ({ data, donorId }) => {
  return fetch({
    url: `/dms/donor/relatives/${donorId}`,
    method: "PUT",
    data,
  });
}

ApiService.getServiceRequests = (profile_id) => {
  return fetch({
    url: `/dms/service-request/${profile_id}`,
    method: "GET",
  });
}

ApiService.updateServiceRequestStatus = ({ profile_id, enquiry_id }) => {
  return fetch({
    url: `/dms/update-request-status/${enquiry_id}`,
    method: "PUT",
    data: {
      profile_id,
    }
  });
}

ApiService.downloadDmsReceipt = (id) => {
  return fetch({
    url: `/dms/download-dms-receipt/${id}`,
    method: "GET",
    responseType: "blob",
  })
}


ApiService.sendUsrTrackData = (data) => {
  return fetch({
    url: "/track-user",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.trackUserUpdate = (data) => {
  return fetch({
    url: "/track-user-update",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};




export default ApiService;
